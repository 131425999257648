/* numberUtils  ------------------------------------------------------------- */
export const handleNumberVerification = (value: string | number, toFixed?: number): number => {
  const factor = Math.pow(10, toFixed || 0)

  const round = (number: number) => Math.round(number * factor) / factor

  if (typeof value === 'number') {
    return toFixed !== undefined ? round(value) : value
  }
  const newValue = toFixed !== undefined ? round(parseFloat(value)) : parseFloat(value)
  return isNaN(newValue) ? 0 : newValue
}

export const handleNumberVerificationAllowEmpty = (value: string | number, toFixed?: number): number | '' => {
  const factor = Math.pow(10, toFixed || 0)

  const round = (number: number) => Math.round(number * factor) / factor

  if (typeof value === 'number') {
    return toFixed !== undefined ? round(value) : value
  }
  const newValue = toFixed !== undefined ? round(parseFloat(value)) : parseFloat(value)
  return isNaN(newValue) ? '' : newValue
}

export const parseNumber = (value?: string | number | null): number => {
  if (!value) return 0
  if (typeof value === 'string') return parseFloat(value)
  return value
}

export const formatPrice = (value: number): string => {
  if (!value) return '0,00 €'

  return `${value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
}

export const verifyNumberField = (event: React.ChangeEvent<HTMLInputElement>, onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void) => {
  if (!onChange) return

  let value = event.target.value.replace(',', '.')
  const [ integerPart, decimalPart ] = value.split('.')

  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
    value = decimalPart && decimalPart.length > 2 ? `${integerPart}.${decimalPart.slice(0, 2)}` : value
    onChange({ ...event, target: { ...event.target, value }})
  }
}
