/* Component imports -------------------------------------------------------- */
import CaseHistoryPage from 'pages/CaseHistoryPage/CaseHistoryPage'
import CaseInvoicePage from 'pages/CaseInvoicePage/CaseInvoicePage'
import CaseMeasurePage from 'pages/CaseMeasurePage/CaseMeasurePage'
import CaseOrderPage from 'pages/CaseOrderPage/CaseOrderPage'
import CaseQuotePage from 'pages/CaseQuotePage/CaseQuotePage'
import CaseTaskPage from 'pages/CaseTaskPage/CaseTaskPage'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import QuoteInvoicePage from 'pages/QuoteInvoicePages/QuoteInvoicePage'

/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'types/ChipColor'
import {
  EtatDevis,
  WorkflowEtat,
  EtatFacture,
  EtatCommande,
} from 'API/__generated__/Api'

/* Helper function declarations --------------------------------------------- */
export const getCaseStatusColor = (code: string): ChipColor => {
  switch (code) {
    case 'C':
      return 'blue'
    case 'RA':
      return 'red'
    case 'R':
      return 'purple'
    case 'D':
      return 'orange'
    case 'X':
      return 'salmon'
    case 'M':
      return 'green'
    case 'T':
      return 'grey'
    default:
      return 'yellow'
  }
}

export const getJalonStatusColor = (code: WorkflowEtat): ChipColor => {
  switch (code) {
    case WorkflowEtat.EnAttente:
      return 'yellow'
    case WorkflowEtat.Fait:
      return 'green'
    case WorkflowEtat.Brouillon:
      return 'orange'
    case WorkflowEtat.Anomalie:
      return 'red'
    case WorkflowEtat.Annule:
      return 'salmon'
    case WorkflowEtat.NonFait:
      return 'grey'
    case WorkflowEtat.NonAboutie:
      return 'yellow'
  }
}

export const getQuoteStatusColor = (code: EtatDevis): ChipColor => {
  switch (code) {
    case EtatDevis.Valide:
      return 'blue'
    case EtatDevis.Refuse:
      return 'red'
    case EtatDevis.Transmis:
      return 'purple'
    case EtatDevis.Retransmis:
      return 'purple'
    case EtatDevis.Brouillon:
      return 'orange'
    case EtatDevis.Regle:
      return 'green'
    default:
      return 'yellow'
  }
}

export const getInvoiceStatusColor = (code: EtatFacture): ChipColor => {
  switch (code) {
    case EtatFacture.Valide:
      return 'blue'
    case EtatFacture.Refuse:
      return 'red'
    case EtatFacture.Envoye:
      return 'purple'
    case EtatFacture.Renvoye:
      return 'purple'
    case EtatFacture.Brouillon:
      return 'orange'
    case EtatFacture.Regle:
      return 'green'
    case EtatFacture.Partiel:
      return 'lightblue'
    case EtatFacture.Annule:
      return 'grey'
    default:
      return 'yellow'
  }
}

export const getOrderStatusColor = (code: EtatCommande): ChipColor => {
  switch (code) {
    case EtatCommande.Transmis:
      return 'purple'
    case EtatCommande.Brouillon:
      return 'orange'
    case EtatCommande.Regle:
      return 'green'
    default:
      return 'yellow'
  }
}

interface CaseTab {
  label: string;
  path: string;
  component: React.ComponentType<object>;
  hide?: boolean;
  props?: object;
}

export const caseTabs: CaseTab[] = [
  {
    path: 'historique',
    label: 'Historique',
    component: CaseHistoryPage,
  },
  {
    path: 'taches',
    label: 'Tâches',
    component: CaseTaskPage,
  },
  {
    path: 'messages',
    label: 'Messages',
    component: MessagesPage,
  },
  {
    path: 'metre',
    label: 'Métré',
    component: CaseMeasurePage,
  },
  {
    path: 'devis',
    label: 'Devis',
    component: CaseQuotePage,
  },
  {
    path: 'devis/edition/:id',
    label: 'Devis',
    component: QuoteInvoicePage,
    hide: true,
    props: { edit: true },
  },
  {
    path: 'devis/nouveau',
    label: 'Devis',
    component: QuoteInvoicePage,
    hide: true,
  },
  {
    path: 'commande',
    label: 'Commande',
    component: CaseOrderPage,
  },
  {
    path: 'facture',
    label: 'Facture',
    component: CaseInvoicePage,
  },
  {
    path: 'facture/edition/:id',
    label: 'Facture',
    component: QuoteInvoicePage,
    hide: true,
    props: { edit: true, isInvoice: true },
  },
  {
    path: 'facture/nouveau',
    label: 'Facture',
    component: QuoteInvoicePage,
    hide: true,
    props: { isInvoice: true },
  },
  {
    path: 'cout-de-revient',
    label: 'Coût de revient',
    component: CaseHistoryPage,
  },
]

export const periodList = [
  {
    value: new Date('2000-01-01').toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: 'Tout',
  },
  {
    value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '7 derniers jours',
  },
  {
    value: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '30 derniers jours',
  },
  {
    value: new Date(Date.now() - 93 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '3 derniers mois',
  },
  {
    value: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: "L'année passée",
  },
]
