/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePatchCaseMissionMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Mission,
  MissionDossierPartialUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const missionSchema = Yup.object().shape<Shape<MissionDossierPartialUpdatePayload>>({
  autreReference: Yup.string(),
  // date: Yup.string().required('La date est obligatoire'),
  // nature: Yup.string().required('La nature est obligatoire'),
  // referenceMission: Yup.string().required('La ref est obligatoire'),
})

type MissionForm = FormikContextType<MissionDossierPartialUpdatePayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  div {
    width: 100%;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const AddressSeparator = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutMissionModalProps {
  handleClose: () => void;
  caseId: string;
  mission: Mission;
}

const CaseLayoutMissionModal: React.FC<CaseLayoutMissionModalProps> = ({
  handleClose,
  caseId,
  mission,
}) => {
  const [
    submitMission,
  ] = usePatchCaseMissionMutation()
  // const {
  //   currentData: missionList = [],
  //   isFetching: isFetchingMissionList,
  // } = useGetMissionListQuery()

  const onSubmit = (values: MissionDossierPartialUpdatePayload, { setSubmitting }: FormikHelpers<MissionDossierPartialUpdatePayload>) => {
    submitMission({ caseId, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const formikForm: MissionForm = useForm<MissionDossierPartialUpdatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        missionUrgente: false,
      },
      validationSchema: missionSchema,
    },
  )

  useEffect(() => {
    // formikForm.setValues(mission)
  }, [ mission ])

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Modifier la mission
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          <DialogContentContainer>
            <DualContainer>
              <div>
                <FormBoldTitle required>
                  Nature du sinistre
                </FormBoldTitle>
                {/* <Field
                  component={Select}
                  name="nature"
                  displayEmpty
                  size="small"
                  disabled={isFetchingMissionList}
                >
                  <MenuItem value="">
                    Sélectionner
                  </MenuItem>
                  {
                    missionList.map((value, index) => (
                      <MenuItem
                        value={value.libelle || ''}
                        key={`${value.libelle}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field> */}
              </div>
              <div>
                <FormBoldTitle required>
                  Ref sinistre
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ref sinistre"
                  name="referenceMission"
                  size="small"
                />
              </div>
            </DualContainer>
            <FormBoldTitle required>
              Date
            </FormBoldTitle>
            <FormikDatePicker name="date" />
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              name="commentaire"
              size="small"
              multiline
              rows={3}
            />
          </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseLayoutMissionModal
