/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import {
  EditOutlined,
  FmdGoodRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import CaseLayoutDisasterModal from './CaseLayoutDisasterEditModal'

/* Type imports ------------------------------------------------------------- */
import type { Sinistre } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const AddressContainer = styled.div`
  display: flex;
  gap: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

const Flex = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryDisasterProps {
  disaster: Sinistre;
  caseId: string;
}

const CaseLayoutSummaryDisaster: React.FC<CaseLayoutSummaryDisasterProps> = ({
  disaster,
  caseId,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(false)
  const [ openDisaster, setOpenDisaster ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown
      >
        <Flex>
          Sinistre
          <CustomIconButton
            Icon={EditOutlined}
            onClick={(e) => {e.stopPropagation(); setOpenDisaster(true)}}
            color="secondary"
            label="Modifier le sinistre"
            size="small"
          />
        </Flex>
        <DropDownArrow open={expanded} />
      </BoldTitle>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <div>
          <b>
            {disaster.nature}
          </b>
          {` - ${DateUtils.APIStrToLocalDateString(disaster.date)}`}
        </div>
        <AddressContainer>
          {
            getAddress(disaster.adresse) &&
              <CustomIconButton
                Icon={FmdGoodRounded}
                // onClick={onLocationClick}
                disabled
                variant="outlined"
                color="secondary"
                label="Voir sur la carte"
              />
          }
          <div>
            {getAddress(disaster.adresse)}
          </div>
        </AddressContainer>
        <div>
          {disaster.sinistreCommentaire}
        </div>
      </Collapse>
      {
        openDisaster &&
          <CaseLayoutDisasterModal
            caseId={caseId}
            disaster={disaster}
            handleClose={() => setOpenDisaster(false)}
          />
      }
    </div>
  )
}

export default CaseLayoutSummaryDisaster
