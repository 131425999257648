/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useNavigate,
} from 'react-router-dom'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'
import {
  onLogoutReset,
  useAuthInfo,
} from 'store/hooks'
import { useIsDesktop } from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Divider,
  Drawer,
  IconButton,
} from '@mui/material'
import {
  LogoutRounded,
  Menu,
} from '@mui/icons-material'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import MainLayoutNavTabs from './MainLayoutComponents/MainLayoutNavTabs'
import MainLayoutSearchField from './MainLayoutComponents/MainLayoutSearchField'

/* Styled components -------------------------------------------------------- */
const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.colors.main};
  font-weight: bold;
  display: flex;
  justify-content: start;
  font-size: 1.5rem;
  padding: 10px;
`

const HeaderTitleButton = HeaderTitleContainer.withComponent(Button)

const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    width: min(80%, 400px);
  }
`

const DrawerContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.colors.main};
  height: 100%;

  .side-divider {
    background-color: ${(props) => props.theme.colors.main};
  }
`

const MobileHeaderCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: initial;
  background-color: ${(props) => props.theme.palette.secondary.main};
  margin: -2px;
  height: 50px;
`

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @media ${(props) => props.theme.media.desktop} {
    padding-top: 10px;
    flex-direction: row;
  }

  .side-divider {
    background-color: ${(props) => props.theme.colors.main};
    margin: 0px 10px;
  }
`

const DesktopMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${(props) => props.theme.palette.secondary.main};
  width: 220px;
  margin: 10px;
  margin-top: 0px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.main};
  height: calc(100vh - 20px);
  flex-shrink: 0;
`

const AccountContainer = styled.div`
  display: flex;
  margin: 10px;
  align-items: center;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  useMobileStatusBarStyle('black')
  const isDesktop = useIsDesktop()
  const auth = useAuthInfo()
  const navigate = useNavigate()
  const [ openDrawer, setOpenDrawer ] = useState<boolean>(false)

  const onLogout = () => {
    onLogoutReset()
  }

  const navigateToMainPage = () => {
    navigate('/')
  }

  return (
    <BaseLayout>
      <ErrorBoundaryPage>
        <LayoutContainer>
          {
            isDesktop ?
              <DesktopMenu>
                <div>
                  <HeaderTitleButton
                    onClick={navigateToMainPage}
                    fullWidth
                  >
                    IREN
                  </HeaderTitleButton>
                  <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
                  <MainLayoutNavTabs closeDrawer={() => setOpenDrawer(false)} />
                </div>
                <div>
                  <Divider className="side-divider" />
                  <AccountContainer>
                    <CustomIconButton
                      Icon={LogoutRounded}
                      variant="contained"
                      onClick={onLogout}
                      label="Déconnexion"
                    />
                    {auth?.nom}
                  </AccountContainer>
                </div>
              </DesktopMenu> :
              <React.Fragment>
                <MobileHeaderCard>
                  <HeaderTitleButton onClick={navigateToMainPage}>
                    IREN
                  </HeaderTitleButton>
                  <IconButton
                    onClick={() => setOpenDrawer(true)}
                    color="info"
                  >
                    <Menu />
                  </IconButton>
                </MobileHeaderCard>
                <DrawerContainer
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  anchor="right"
                >
                  <DrawerContent>
                    <div>
                      <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
                      <MainLayoutNavTabs closeDrawer={() => setOpenDrawer(false)} />
                    </div>
                    <div>
                      <Divider className="side-divider" />
                      <AccountContainer>
                        <CustomIconButton
                          Icon={LogoutRounded}
                          variant="contained"
                          onClick={onLogout}
                          label="Déconnexion"
                        />
                        {auth?.nom}
                      </AccountContainer>
                    </div>
                  </DrawerContent>
                </DrawerContainer>
              </React.Fragment>
          }
          <Outlet />
        </LayoutContainer>
      </ErrorBoundaryPage>
    </BaseLayout>
  )
}

export default MainLayout
