/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'
import JSZip from 'jszip'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { useForm } from 'components/FormikLogic/FormikLogic'
import { useAppDispatch } from 'store/hooks'
import {
  addDocument,
  setOpenMedia,
} from 'store/slices/layoutSlice'
import { getRowsComparator } from 'helpers/tableUtils'
import {
  downloadFile,
  getCleanFilename,
} from 'helpers/mediaUtils'
import { useGetCaseMediaListQuery } from 'store/api'
import { getMediaFileType } from 'helpers/getMediaFileType'

/* Component imports -------------------------------------------------------- */
import {
  Divider,
  IconButton,
} from '@mui/material'
import {
  AddPhotoAlternateOutlined,
  Close,
  Download,
  Edit,
  EditOff,
  FilterList,
  OpenInNew,
  SwapVert,
} from '@mui/icons-material'
import ImportFilesModal from 'components/ImportFilesModal/ImportFilesModal'
import CaseLayoutMediaThumbnail from './CaseLayoutMediaThumbnail'
import CaseLayoutMediaFileDisplay from './CaseLayoutMediaFileDisplay'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  DataName,
  Order,
} from 'types/Table'
import type { Document } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const documentsSchema = Yup.object({
  filters: Yup.object({
    documentTypes: Yup.array(Yup.string()),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type DocumentsSchema = Yup.InferType<typeof documentsSchema>

type DocumentsForm = FormikContextType<DocumentsSchema>

type SubMenu = 'sort' | 'filter'

/* Styled components -------------------------------------------------------- */
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const BoldSeparator = styled(Divider)`
  margin: 5px 0px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

const DisplayLargeContainer = styled.div`
  height: calc(100% - 80px);
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutMediaProps {
  documents: Document[];
}

const CaseLayoutMedia: React.FC<CaseLayoutMediaProps> = ({ documents }) => {
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ selected, setSelected ] = useState<Document[]>([])
  const [ edit, setEdit ] = useState<boolean>(false)
  const [ filteredDocumentList, setFilteredDocumentList ] = useState<Document[]>(documents)
  const [ openUpload, setOpenUpload ] = useState<boolean>(false)
  const [ openSubMenu, setOpenSubMenu ] = useState<SubMenu | null>(null)
  const [ displayLarge, setDisplayLarge ] = useState<Document | null>(null)

  const {
    currentData: documentList = [],
    isFetching: isFetchingDocumentList,
  } = useGetCaseMediaListQuery({ dossier: caseId })

  const ref = useRef<HTMLDivElement | null>(null)

  const formikForm: DocumentsForm = useForm<DocumentsSchema>(
    {
      initialValues: {
        filters: {
          documentTypes: [],
        },
        sort: {
          order: 'desc',
          orderBy: 'dateCreation',
        },
      },
      validationSchema: documentsSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingDocumentList) {
      setFilteredDocumentList([ ...documents, ...documentList.map((doc): Document => ({ ...doc, nomDuFichier: doc.fileName })) ])
    }
  }, [ isFetchingDocumentList, documents, caseId ])

  useEffect(() => {
    const { documentTypes = []} = formikForm.values.filters
    const { order, orderBy } = formikForm.values.sort

    setFilteredDocumentList([ ...documents, ...documentList.map((doc): Document => ({ ...doc, nomDuFichier: doc.fileName })) ].sort(getRowsComparator(order, orderBy)).filter((document) =>
      (documentTypes.length === 0 || documentTypes.includes(document.categorie?.code)),
    ))
  }, [ formikForm.values.filters ])

  const onClose = () => {
    dispatch(setOpenMedia(false))
  }

  const openOutsideDocument = (doc: Document) => {
    const maxWindowWidth = window.innerWidth * 0.75
    const maxWindowHeight = window.innerHeight * 0.75

    if (getMediaFileType(doc) === 'img') {
      const img = new Image()
      img.src = doc.url || ''

      img.onload = () => {
        const { width, height } = img
        let finalSize = { width, height }

        if (width <= maxWindowWidth && height <= maxWindowHeight) {
          finalSize = { width, height }
        } else {
          const widthRatio = maxWindowWidth / width
          const heightRatio = maxWindowHeight / height
          const scale = Math.min(widthRatio, heightRatio)

          finalSize = {
            width: Math.floor(width * scale),
            height: Math.floor(height * scale),
          }
        }
        dispatch(addDocument({ caseId, document: doc, height: finalSize.height + 30, width: finalSize.width }))
      }

    } else {
      dispatch(addDocument({ caseId, document: doc, height: maxWindowHeight }))
    }
  }

  const openLargeDocument = (document: Document) => {
    setDisplayLarge(document)
  }

  const onClickOpenSubMenu = (value: SubMenu) => {
    if (value === openSubMenu) {
      setOpenSubMenu(null)
    } else {
      setOpenSubMenu(value)
    }
  }

  const onDownloadButtonClick = () => {
    const zip = new JSZip()
    const files = selected.length > 0 ? selected : documents

    files.forEach((file) => {
      if (!file.url) {
        return
      }
      const blobPromise = fetch(file.url).then((r) => {
        if (r.status === 200) {
          return r.blob()
        }
        throw Promise.reject(new Error(r.statusText))
      })
      zip.file(getCleanFilename(file.libelle, file.nomDuFichier), blobPromise)
    })
    zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, `${caseId}_mediathèque`)).catch(console.error)
  }

  const onFileDonwloadClick = (document: Document) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    downloadFile(getCleanFilename(document.libelle, document.nomDuFichier || ''), document.url)
  }

  // const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  // const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <SummaryContainer ref={ref}>
      <BoldTitle>
        {`Médiathèque${displayLarge ? ` : ${displayLarge.libelle}` : ''}`}
        {
          !displayLarge ?
            <div>
              <IconButton
                color="primary"
                onClick={() => setOpenUpload(true)}
              >
                <AddPhotoAlternateOutlined />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => onDownloadButtonClick()}
              >
                <Download />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => onClickOpenSubMenu('filter')}
              >
                <FilterList />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => onClickOpenSubMenu('sort')}
              >
                <SwapVert />
              </IconButton>
              <IconButton
                color="primary"
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </div> :
            <div>
              <IconButton
                color="primary"
                onClick={() => setEdit(!edit)}
              >
                {
                  edit ?
                    <EditOff />:
                    <Edit />
                }
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {setDisplayLarge(null); setEdit(false); openOutsideDocument(displayLarge)}}
              >
                <OpenInNew />
              </IconButton>
              <IconButton
                color="primary"
                onClick={onFileDonwloadClick(displayLarge)}
              >
                <Download />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {setDisplayLarge(null); setEdit(false)}}
              >
                <Close />
              </IconButton>
            </div>
        }
      </BoldTitle>
      {
        openSubMenu &&
          <div>
            {
              openSubMenu === 'filter' ?
                <div>
                  filtre
                </div>:
                <div>
                  sort
                </div>
            }
          </div>
      }
      <BoldSeparator />
      {
        !displayLarge ?
          <GridContainer>
            {
              filteredDocumentList.map((document, index) => (
                <CaseLayoutMediaThumbnail
                  key={`${document.libelle}-${index}`}
                  selected={selected}
                  setSelected={setSelected}
                  document={document}
                  onDisplayLargeClick={() => openLargeDocument(document)}
                  onDisplayOutsideClick={() => openOutsideDocument(document)}
                />
              ))
            }
          </GridContainer> :
          <DisplayLargeContainer>
            <CaseLayoutMediaFileDisplay
              edit={edit}
              document={displayLarge}
            />
          </DisplayLargeContainer>
      }
      {
        openUpload &&
          <ImportFilesModal
            handleClose={() => setOpenUpload(false)}
            accept="application/pdf,image/*"
          />
      }
    </SummaryContainer>
  )
}

export default CaseLayoutMedia
