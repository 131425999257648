/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getInvoiceStatusColor } from 'helpers/caseUtils'
import { formatPrice } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { DashboardFacture } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface DashboardInvoiceListMobileCardProps {
  invoice: DashboardFacture;
  handleNavigate: () => void;
}

const DashboardInvoiceListMobileCard: React.FC<DashboardInvoiceListMobileCardProps> = ({
  invoice,
  handleNavigate,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link onClick={handleNavigate}>
            {invoice.numeroFacture}
          </Link>
          <ColoredSquareChip
            color={getInvoiceStatusColor(invoice.etat.code)}
            smaller
          >
            {invoice.etat.libelle}
          </ColoredSquareChip>
        </TitleContainer>
        {`Assuré : ${invoice.assure}`}
        <br />
        {`Donneur d'ordre : ${invoice.donneurDordre || ''}`}
        <br />
        {`Ref mandant : ${invoice.refMandant}`}
        <br />
        {`Ville : ${invoice.ville}`}
        <br />
        {`N° Facture : ${invoice.numeroFacture}`}
        <br />
        {`Montant HT : ${formatPrice(invoice.montantHT)}`}
        <br />
        {`Franchise : ${invoice.franchise.montant ? `${formatPrice(invoice.franchise.montant)} - ${invoice.franchise.recuperee ? 'Récupérée' : 'Non récupérée'}` : ''}`}
        <br />
        {`Acompte : ${invoice.acompte.montant ? `${formatPrice(invoice.acompte.montant)} - ${invoice.acompte.recupere ? 'Récupéré' : 'Non récupéré'}` : ''}`}
      </CardContentContainer>
    </CardContainer>
  )
}

export default DashboardInvoiceListMobileCard
