/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { getRowsComparator } from 'helpers/tableUtils'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseMeasureTable from './CaseMeasureComponents/CaseMeasureTable'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  DataName,
  Order,
} from 'types/Table'
import type { PieceJointe } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const measureSchema = Yup.object({
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type MeasureSchema = Yup.InferType<typeof measureSchema>

type MeasureForm = FormikContextType<MeasureSchema>

/* Component declaration ---------------------------------------------------- */
interface CaseMeasurePageProps {}

const CaseMeasurePage: React.FC<CaseMeasurePageProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()
  const [ filteredList, setFilteredList ] = useState<PieceJointe[]>([])

  const formikForm: MeasureForm = useForm<MeasureSchema>(
    {
      initialValues: {
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: measureSchema,
    },
  )

  useEffect(() => {
    const { order, orderBy } = formikForm.values.sort

    // set list
    setFilteredList([ ].sort(getRowsComparator(order, orderBy)))
  }, [ formikForm.values.sort ])

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <div>
      <LargeTitle>
        Métrés
        <Button variant="contained">
          Nouveau métré
        </Button>
      </LargeTitle>
      <Form form={formikForm}>
        <CaseMeasureTable
          filteredList={filteredList}
          isFetchingList={false}
          setFilteredList={() => null}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Form>
    </div>
  )
}

export default CaseMeasurePage
