/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import type { Planning } from 'types/Planning'

/* Styled components -------------------------------------------------------- */
interface WeekEventContainerProps {
  color: string;
}

const WeekEventContainer = styled.div<WeekEventContainerProps>`
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1px 3px;
  justify-content: space-between;
`

const DateContainer = styled.div`
  font-size: .7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 12px;
`

const TitleContainer = styled.div`
  font-weight: bold;
  font-size: .8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StakeholderContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 3px;
`

interface ColoredBarProps {
  color: string;
}

const StakeholderBar = styled.div<ColoredBarProps>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 20px;
  border: 1px solid #00000019;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventWeekViewProps {
  eventInfo: EventContentArg;
  event?: Planning;
}

const PlanningCalendarEventWeekView: React.FC<PlanningCalendarEventWeekViewProps> = ({
  eventInfo,
  event,
}) => {

  return (
    <WeekEventContainer
      id={event ? `${event.commentaire}#${event.typeRDV?.libelle}` : `${eventInfo.event.id}`}
      color={eventInfo.event.backgroundColor}
    >
      <div>
        <DateContainer>
          {eventInfo.timeText}
        </DateContainer>
        <TitleContainer>
          {eventInfo.event.title}
        </TitleContainer>
      </div>
      <StakeholderContainer>
        {
          event?.intervenants?.map((stakeholder, index) => (
            <StakeholderBar
              key={`${stakeholder.id}-${index}`}
              color={stakeholder.color}
            >
              {`${stakeholder.prenom?.[0] || ''}${stakeholder.nom[0]}`}
            </StakeholderBar>
          ))
        }
      </StakeholderContainer>
    </WeekEventContainer>
  )
}

export default PlanningCalendarEventWeekView
