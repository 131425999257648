/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { formatPrice } from 'helpers/numberUtils'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Collapse,
} from '@mui/material'
import {
  EditOutlined,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import type { Mission } from 'API/__generated__/Api'
import CaseLayoutMissionModal from './CaseLayoutMissionEditModal'

/* Styled components -------------------------------------------------------- */
interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  position: relative;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px !important;
`

const Flex = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const BorderedContainer = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.grey}`};
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const UppercaseTitle = styled.div`
  text-transform: uppercase;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryMissionProps {
  mission: Mission;
  caseId: string;
}

const CaseLayoutSummaryMission: React.FC<CaseLayoutSummaryMissionProps> = ({
  mission,
  caseId,
}) => {
  const [ expanded, setExpanded ] = useState<boolean>(false)
  const [ open, setOpen ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown
      >
        <Flex>
          Mission
          <CustomIconButton
            Icon={EditOutlined}
            onClick={(e) => {e.stopPropagation(); setOpen(true)}}
            color="secondary"
            label="Modifier la mission"
            size="small"
          />
        </Flex>
        <DropDownArrow open={expanded} />
      </BoldTitle>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {
          mission.missionUrgente &&
            <b>
              Mission urgente
            </b>
        }
        <BorderedContainer>
          <div>
            {'Entité missionnée : '}
            <b>
              {mission.entiteMissionnee?.libelle}
            </b>
          </div>
          <div>
            {'Entité traitante : '}
            <b>
              {mission.entiteTraintante}
            </b>
          </div>
          <div>
            {'Reçu le : '}
            <b>
              {DateUtils.APIStrToLocalDateString(mission.dateReception)}
            </b>
          </div>
          <div>
            {"Montant d'ouverture : "}
            <b>
              {formatPrice(mission.montantOuverture || 0)}
            </b>
          </div>
          <div>
            {'Code état : '}
            <b>
              {mission.codeEtat?.libelle}
            </b>
          </div>
          <div>
            {"Type d'activité : "}
            <b>
              {mission.typeActivite?.libelle}
            </b>
          </div>
          <div>
            {'TH initial : '}
            <b>
              {formatPrice(mission.thInitial || 0)}
            </b>
          </div>
          <div>
            {'Gestionnaire : '}
            <b>
              {mission.gestionnaire?.libelle}
            </b>
          </div>
          <div>
            {'Autre référence : '}
            <b>
              {mission.autreReference}
            </b>
          </div>
        </BorderedContainer>
        {
          mission.franchise &&
            <BorderedContainer>
              <FlexContainer>
                <div>
                  {'Franchise : '}
                  <b>
                    {formatPrice(mission.franchise?.montant || 0)}
                  </b>
                </div>
                {
                  !mission.franchise.recuperee &&
                    <Button
                      variant="outlined"
                      disabled
                      size="small"
                    >
                      Récuperer
                    </Button>
                }
              </FlexContainer>
              {
                mission.franchise.recuperee &&
                  <div>
                    {`Récupérée le ${DateUtils.APIStrToLocalDateString(mission.franchise.dateRecuperation)} par ${mission.franchise.repreneur}`}
                  </div>
              }
            </BorderedContainer>
        }
        {
          mission.metreSimplifie &&
            <BorderedContainer>
              <UppercaseTitle>
                Métré
              </UppercaseTitle>
              <div>
                <div>
                  {'Métreur : '}
                  <b>
                    {mission.metreSimplifie.metreur?.libelle}
                  </b>
                </div>
                {mission.metreSimplifie.commentaire}
              </div>
            </BorderedContainer>
        }
        {
          mission.travauxSimplifie &&
            <BorderedContainer>
              <UppercaseTitle>
                Travaux
              </UppercaseTitle>
              <div>
                <div>
                  {'Technicien : '}
                  <b>
                    {mission.travauxSimplifie.technicien?.libelle}
                  </b>
                </div>
                {mission.travauxSimplifie.commentaire}
              </div>
            </BorderedContainer>
        }
      </Collapse>
      {
        open &&
          <CaseLayoutMissionModal
            caseId={caseId}
            mission={mission}
            handleClose={() => setOpen(false)}
          />
      }
    </div>
  )
}

export default CaseLayoutSummaryMission
