/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import fuzzysort from 'fuzzysort'

/* Component imports -------------------------------------------------------- */
import { TextField } from '@mui/material'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Type imports ------------------------------------------------------------- */
import type { CodeLabel } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 5px 0px 10px;

  .table-filter {
    height: 30px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface TableFilterListProps {
  items: CodeLabel[];
  checkedItems: string[];
  multiple?: boolean;
  onItemsChange: (items: string | string[]) => void;
}

const TableFilterList: React.FC<TableFilterListProps> = ({
  items,
  checkedItems,
  multiple = false,
  onItemsChange,
}) => {
  const [ value, setValue ] = useState<string>('')
  const [ filteredItems, setFilteredItems ] = useState<CodeLabel[]>([])

  useEffect(() => {
    const basicFilter = items.filter((op) => op.libelle?.includes(value.toLowerCase()))

    const opt = {
      limit: 100, // don't return more results than you need!
      threshold: -100, // don't return bad results
      all: true,
      keys: [ 'code', 'libelle' ],
    }

    const fuzzyResults = fuzzysort.go(value, items, opt).map(({ obj }) => obj).filter((op) => !basicFilter.some((v) => v.code === op.code))
    setFilteredItems([ ...basicFilter, ...fuzzyResults ])
  }, [ value, items ])

  const onClick = (item: CodeLabel) => {
    if (checkedItems.includes(item.code)) {
      onItemsChange(checkedItems.filter((v) => v !== item.code))
    } else {
      if (multiple) {
        onItemsChange([ ...checkedItems, item.code ])
      } else {
        onItemsChange(item.code)
      }
    }
  }

  return (
    <Container>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Rechercher"
        size="small"
      />
      {
        filteredItems.map((item) => (
          <CheckableButton
            key={item.code}
            checked={checkedItems.includes(item.code)}
            label={item.libelle}
            onChange={() => onClick(item)}
            type={multiple ? 'checkbox' : 'radio'}
            className="table-filter"
          />
        ))
      }
    </Container>
  )
}

export default TableFilterList
