/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import type { WorkflowEtat } from 'API/__generated__/Api'
import { getJalonStatusColor } from 'helpers/caseUtils'

/* Styled components -------------------------------------------------------- */
const Chip = styled(ColoredSquareChip)`
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  height: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryJalonProps {
  label?: string | null;
  date?: string | null;
  state?: WorkflowEtat;
}

const CaseHistoryJalon: React.FC<CaseHistoryJalonProps> = ({
  label,
  date,
  state,
}) => {
  return (
    <>
      <div>
        {label}
      </div>
      {
        state &&
          <Chip color={getJalonStatusColor(state)}>
            {state}
            {
              date &&
                <div>
                  {DateUtils.APIStrToLocalDateString(date)}
                </div>
            }
          </Chip>
      }
    </>
  )
}

export default CaseHistoryJalon
