/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAppDispatch } from 'store/hooks'
import { setCaseListPeriod } from 'store/slices/caseListFilterSlice'
import { periodList } from 'helpers/caseUtils'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  CompagnieAssurance,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, calc(16% - 5px));
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const PeriodContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: 25%;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: -15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DashboardQuoteListFiltersProps {
  companyList: CompagnieAssurance[];
  isFetchingCompanyList: boolean;
  disasterNatureList: CodeLabel[];
  isFetchingDisasterNatureList: boolean;
  departmentList: CodeLabel[];
  isFetchingDepartmentList: boolean;
}

const DashboardQuoteListFilters: React.FC<DashboardQuoteListFiltersProps> = ({
  companyList,
  isFetchingCompanyList,
  disasterNatureList,
  isFetchingDisasterNatureList,
  departmentList,
  isFetchingDepartmentList,
}) => {
  const dispatch = useAppDispatch()

  return (
    <>
      <SelectFilterContainer>
        <div>
          <FormBoldTitle>
            Donneur d'ordre
          </FormBoldTitle>
          <AutocompleteField
            name="CompagnieAssurance"
            options={companyList.map((value) => value.id || '')}
            getOptionLabel={(option) => companyList.find((value) => value.id === option)?.nom || ''}
            disabled={isFetchingCompanyList}
            size="small"
            multiple
          />
        </div>
        <div>
          <FormBoldTitle>
            Type de sinistre
          </FormBoldTitle>
          <AutocompleteField
            name="NatureSinistre"
            options={disasterNatureList.map((value) => value.code || '')}
            getOptionLabel={(option) => disasterNatureList.find((value) => value.code === option)?.libelle || ''}
            disabled={isFetchingDisasterNatureList}
            size="small"
            multiple
          />
        </div>
        <div>
          <FormBoldTitle>
            Département
          </FormBoldTitle>
          <AutocompleteField
            name="Departement"
            options={departmentList.map((value) => value.code || '')}
            getOptionLabel={(option) => departmentList.find((value) => value.code === option)?.libelle || ''}
            disabled={isFetchingDepartmentList}
            size="small"
          />
        </div>
        <div>
          <FormBoldTitle>
            Acteurs
          </FormBoldTitle>
          <AutocompleteField
            name="CompagnieAssurance"
            options={companyList.map((value) => value.id || '')}
            getOptionLabel={(option) => companyList.find((value) => value.id === option)?.nom || ''}
            disabled={isFetchingCompanyList}
            size="small"
            multiple
          />
        </div>
        <div>
          <FormBoldTitle>
            Etablissements
          </FormBoldTitle>
          <AutocompleteField
            name="CompagnieAssurance"
            options={companyList.map((value) => value.id || '')}
            getOptionLabel={(option) => companyList.find((value) => value.id === option)?.nom || ''}
            disabled={isFetchingCompanyList}
            size="small"
            multiple
          />
        </div>
        <div>
          <FormBoldTitle>
            Compagnie d'assurance
          </FormBoldTitle>
          <AutocompleteField
            name="CompagnieAssurance"
            options={companyList.map((value) => value.id || '')}
            getOptionLabel={(option) => companyList.find((value) => value.id === option)?.nom || ''}
            disabled={isFetchingCompanyList}
            size="small"
            multiple
          />
        </div>
      </SelectFilterContainer>
      <PeriodContainer>
        <FormBoldTitle>
          Période à afficher
        </FormBoldTitle>
        <Field
          component={Select}
          name="DateDebut"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dispatch(setCaseListPeriod(e.target.value))}
          displayEmpty
          size="small"
        >
          {
            periodList.map((value, index) => (
              <MenuItem
                value={value.value}
                key={`${value.value}-${index}`}
              >
                {value.label}
              </MenuItem>
            ))
          }
        </Field>
      </PeriodContainer>
    </>
  )
}

export default DashboardQuoteListFilters
