/* eslint-disable @typescript-eslint/no-unused-vars */
/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  MissionnementMotifRefus,
  MissionnementMotifsRefusListParams,
  Anomalie,
  AnomalieDossierCreatePayload,
  AnomalieDossierUpdatePayload,
  AnomalieType,
  AnomalieTypeUpdatePayload,
  AnomalieTypeCreatePayload,
  LoginResponse,
  Dossier,
  WorkflowEntreprise,
  CodeLabel,
  RendezVousMetreurRequest,
  RendezVousTravauxRequest,
  TauxTVA,
  LoginRequest,
  ResultatRechercheDossier,
  RechercheDossierListParams,
  CompagnieAssurance,
  Devis,
  PiedDevis,
  ImportDevisCreatePayload,
  Eurogex,
  CoordoneesRequest,
  PriseDeContactRequest,
  Bordereau,
  Document,
  ElementMediatheque,
  MediathequeDossierDetailParams,
  DocumentDossierDetailParams,
  MissionnementEtatRequest,
  PiedFacture,
  ImportFactureCreatePayload,
  ValidationProblemDetails,
  EchangeInterne,
  EchangeInterneListParams,
  DestinatairesEchangeInterneDetailParams,
  NouveauEchangeInterneCreatePayload,
  RepondreEchangeInterneCreatePayload,
  TransfertEchangeInterneCreatePayload,
  InterventionDossierCreatePayload,
  InterventionMotifNonReparation,
  ResultatInterventionEnumLabel,
  OrigineDemandeInterventionDiffereeEnumLabel,
  DocumentDossierCreatePayload,
  MediathequeDossierCreatePayload,
  BordereauxDossierDetailParams,
  PiedDevisCreatePayload,
  BrouillonDevisCreatePayload,
  PiedFactureCreatePayload,
  BrouillonFactureCreatePayload,
  EditerFactureCreatePayload,
  Facture,
  SuiviSimplifie,
  ResultatDashboardFacture,
  DevisDashboardListParams,
  FacturesDashboardListParams,
  EtatDevisEnumLabel,
  ResultatDashboardDevis,
  EtatFactureEnumLabel,
  ResultatDashboardCommande,
  CommandesDashboardListParams,
  EtatCommandeEnumLabel,
  PostDossierPayload,
  IdLabel,
  Sinistre,
  Mission,
  SinistreDossierPartialUpdatePayload,
  MissionDossierPartialUpdatePayload,
  Mail,
  MailDossierDetailParams,
  MailDossierCreatePayload,
  DestinatairesMailDetailParams,
  MailDestinataire,
  MailType,
} from 'API/__generated__/Api'

/* API Redux service -------------------------------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, ValidationProblemDetails>,
    tagTypes: [
      'AUTH',
      'MESSAGES',
      'INFO',
      'WORKFLOW',
      'WORKS_RDV',
      'MEASURE_RDV',
      'MEDIAS',
      'ISSUES',
      'INVOICE',
      'HISTORY',
      'MAILS',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        authenticate: build.mutation<LoginResponse, LoginRequest>({
          queryFn: async (args)=> processRequest(
            OpenAPI.authentification.loginIrenAuthentificationCreate,
            args,
          ),
        }),
        /* Issue */
        getIssuesList: build.query<Anomalie[], string>({
          queryFn: async (args, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.dossier.anomaliesDossierDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'ISSUES' },
          ],
        }),
        getIssue: build.query<Anomalie, number>({
          queryFn: async (args, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.dossier.anomalieDossierDetail,
            args,
          ),
        }),
        postIssue: build.mutation<void, {caseId: string; data: AnomalieDossierCreatePayload}>({
          queryFn: async (args, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.dossier.anomalieDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'ISSUES' ],
        }),
        patchIssue: build.mutation<void, {id: number; data: AnomalieDossierUpdatePayload}>({
          queryFn: async (args, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.dossier.anomalieDossierUpdate,
            args.id,
            args.data,
          ),
          invalidatesTags: [ 'ISSUES' ],
        }),
        /* Issue Type */
        getIssueTypesList: build.query<AnomalieType[], void>({
          queryFn: (arg, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.anomalieTypes.anomalieTypesList),
        }),
        getIssueType: build.query<AnomalieType, number>({
          queryFn: (arg, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.anomalieType.anomalieTypeDetail,
            arg,
          ),
        }),
        patchIssueType: build.mutation<void, {id: number;data: AnomalieTypeUpdatePayload}>({
          queryFn: (args, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.anomalieType.anomalieTypeUpdate,
            args.id,
            args.data,
          ),
          invalidatesTags: [ 'ISSUES' ],
        }),
        postIssueType: build.mutation<void, AnomalieTypeCreatePayload>({
          queryFn: (arg, api, extraOptions, baseQuery) => processRequest(
            OpenAPI.anomalieType.anomalieTypeCreate,
            arg,
          ),
        }),
        /* Dashbord Quote Invoice */
        getQuoteList: build.query<ResultatDashboardDevis, DevisDashboardListParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dashboard.devisDashboardList,
            args,
          ),
          keepUnusedDataFor: 3600,
        }),
        getQuoteStatusList: build.query<EtatDevisEnumLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.etatsDevisList,
          ),
          keepUnusedDataFor: 3600,
        }),
        getInvoiceList: build.query<ResultatDashboardFacture, FacturesDashboardListParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dashboard.facturesDashboardList,
            args,
          ),
          keepUnusedDataFor: 3600,
        }),
        getInvoiceStatusList: build.query<EtatFactureEnumLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.etatsFactureList,
          ),
          keepUnusedDataFor: 3600,
        }),
        getOrderList: build.query<ResultatDashboardCommande, CommandesDashboardListParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dashboard.commandesDashboardList,
            args,
          ),
          keepUnusedDataFor: 3600,
        }),
        getOrderStatusList: build.query<EtatCommandeEnumLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.etatsCommandesList,
          ),
          keepUnusedDataFor: 3600,
        }),
        /* Dashbord Cases */
        getCaseList: build.query<ResultatRechercheDossier, RechercheDossierListParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.rechercheDossierList,
            args,
          ),
          keepUnusedDataFor: 3600,
        }),
        getDepartmentList: build.query<CodeLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.departementsList,
          ),
        }),
        getDisasterList: build.query<CodeLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.naturesSinistresList,
          ),
        }),
        getCaseInsuranceCompanyList: build.query<CompagnieAssurance[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.compagniesDAssuranceList,
          ),
        }),
        /* Case */
        getCaseInfos: build.query<Dossier, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.dossierDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'INFO', id: '' },
          ],
        }),
        getCaseDisaster: build.query<Sinistre, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.sinistreDossierDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'INFO', id: '' },
          ],
        }),
        patchCaseDisaster: build.mutation<void, {caseId: string; data: SinistreDossierPartialUpdatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.sinistreDossierPartialUpdate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'INFO' ],
        }),
        getCaseMission: build.query<Mission, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.missionDossierDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'INFO', id: '' },
          ],
        }),
        patchCaseMission: build.mutation<void, {caseId: string; data: MissionDossierPartialUpdatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.missionDossierPartialUpdate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'INFO' ],
        }),
        getCaseWorkflow: build.query<WorkflowEntreprise, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.workflowEntrepriseDossierDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'WORKFLOW' },
          ],
        }),
        patchInsuredInformation: build.mutation<void, {caseId: string; data: CoordoneesRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.coordonneesAssurePartialUpdate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'INFO' ],
        }),
        patchWorkflowMissionState: build.mutation<void, {caseId: string; data: MissionnementEtatRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.etatMissionnementPartialUpdate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'INFO' ],
        }),
        getRefuseMissionReasonList: build.query<MissionnementMotifRefus[], MissionnementMotifsRefusListParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.missionnementMotifsRefus.missionnementMotifsRefusList,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'INFO', id: '' },
            { type: 'WORKFLOW' },
          ],
        }),
        getCaseHistory: build.query<SuiviSimplifie[], string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.historiqueCompletDossierDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'HISTORY', id: 'LIST' },
            ...result.map((r) => ({ type: 'HISTORY' as const, id: `${r.id}` })),
          ],
        }),
        postNewCaseEvent: build.mutation<void, {caseId: string; data: PostDossierPayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.postDossier,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'HISTORY' ],
        }),
        getEventActionList: build.query<IdLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.actionsSuiviList,
          ),
        }),
        /* Mails */
        getCaseMailList: build.query<Mail[], MailDossierDetailParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.mailDossierDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'MAILS', id: 'LIST' },
            ...result.map((r) => ({ type: 'MAILS' as const, id: `${r.id}` })),
          ],
        }),
        postMail: build.mutation<void, { caseId: string; data: MailDossierCreatePayload }>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.mailDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'MAILS' ],
        }),
        getMailRecipientList: build.query<MailDestinataire[], DestinatairesMailDetailParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.destinatairesMailDetail,
            args,
          ),
        }),
        getMailSenderList: build.query<MailDestinataire[], string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.emetteursMailDetail,
            args,
          ),
        }),
        getMailTypeList: build.query<CodeLabel[], string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.mailTypesMailDetail,
            args,
          ),
        }),
        getMailType: build.query<MailType, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.mail.mailTypeMailDetail,
            args,
          ),
        }),
        /* Documents */
        getCaseDocumentList: build.query<Document[], DocumentDossierDetailParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.documentDossierDetail,
            args,
          ),
        }),
        postDocuments: build.mutation<void, { caseId: string; data: DocumentDossierCreatePayload }>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.documentDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'INFO' ],
        }),
        getCaseMediaList: build.query<ElementMediatheque[], MediathequeDossierDetailParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.mediathequeDossierDetail,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'MEDIAS', id: 'LIST' },
            ...result.map((r) => ({ type: 'MEDIAS' as const, id: `${r.id}` })),
          ],
        }),
        postMediaFiles: build.mutation<void, { caseId: string; data: MediathequeDossierCreatePayload }>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.mediathequeDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'MEDIAS', 'INFO' ],
        }),
        getDocumentCategoryList: build.query<CodeLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.mediatheque.categorieMediathequeList,
          ),
        }),
        deleteDocuments: build.mutation<void, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.document.documentDelete,
            args,
          ),
          invalidatesTags: [ 'WORKFLOW', 'INFO', 'INVOICE' ],
        }),
        deleteMediaFiles: build.mutation<void, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.mediatheque.mediathequeDelete,
            args,
          ),
          invalidatesTags: [ 'WORKFLOW', 'INFO', 'MEDIAS', 'INVOICE' ],
        }),
        /* Intervention */
        postIntervention: build.mutation<void, {caseId: string; data: InterventionDossierCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.interventionDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW' ],
        }),
        getInterventionReasonList: build.query<InterventionMotifNonReparation[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.intervention.motifInterventionList,
          ),
        }),
        getInterventionResultList: build.query<ResultatInterventionEnumLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.intervention.resultatInterventionInterventionList,
          ),
        }),
        getInterventionOriginRequestDeferred: build.query<OrigineDemandeInterventionDiffereeEnumLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.intervention.origineDemandeInterventionDiffereeInterventionList,
          ),
        }),
        /* Contact */
        postContactRdv: build.mutation<void, {caseId: string; data: PriseDeContactRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.priseDeContactDossierCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'ISSUES' ],
        }),
        /* Rendez-vous */
        postMeasureRdv: build.mutation<void, {caseId: string; data: RendezVousMetreurRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.metreurRendezVousCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'MEASURE_RDV' ],
        }),
        patchMeasureRdv: build.mutation<void, {id: string; data: RendezVousMetreurRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.rendezVous.metreurRendezVousPartialUpdate,
            args.id,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'MEASURE_RDV' ],
        }),
        postWorksRdv: build.mutation<void, {caseId: string; data: RendezVousTravauxRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.travauxRendezVousCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'WORKS_RDV' ],
        }),
        patchWorksRdv: build.mutation<void, {id: string; data: RendezVousTravauxRequest}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.rendezVous.travauxRendezVousPartialUpdate,
            args.id,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'WORKS_RDV' ],
        }),
        /* Quote & Invoice */
        getFranchise: build.query<number, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.franchiseDossierDetail,
            args,
          ),
        }),
        getRSE: build.query<number, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.rseDossierDetail,
            args,
          ),
        }),
        getRemise: build.query<number, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.remiseDossierDetail,
            args,
          ),
        }),
        getPriceList: build.query<CodeLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.tarifsList,
          ),
        }),
        getMeasureUnitList: build.query<CodeLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.unitesMesureList,
          ),
        }),
        getTVARateList: build.query<TauxTVA[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.tauxTvaList,
          ),
        }),
        getLineTypeList: build.query<CodeLabel[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.typesDeLigneDevisList,
          ),
        }),
        getArticleBordereauList: build.query<Bordereau[], BordereauxDossierDetailParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.bordereauxDossierDetail,
            args,
          ),
        }),
        getDiverseBordereauList: build.query<Bordereau[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.selectList.bibliothequeDiversList,
          ),
        }),
        /* Quote */
        getQuoteDraft: build.query<Devis, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.devisDevisDetail,
            args,
          ),
        }),
        postCalculQuoteEnd: build.mutation<PiedDevis, {data: PiedDevisCreatePayload; caseId: string}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.piedDevisCreate,
            args.caseId,
            args.data,
          ),
        }),
        postSaveQuoteDraft: build.mutation<void, {caseId: string; data: BrouillonDevisCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.brouillonDevisCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW' ],
        }),
        postSaveQuote: build.mutation<void, {caseId: string; data: BrouillonDevisCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.editerDevisCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'INFO' ],
        }),
        postImportQuote: build.mutation<Devis, {caseId: string; data: ImportDevisCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.importDevisCreate,
            args.caseId,
            args.data,
          ),
        }),
        /* Invoice */
        getInvoiceDraft: build.query<Facture, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.factureFactureDetail,
            args,
          ),
          providesTags: (result, error, arg) => [
            { type: 'INVOICE' },
          ],
        }),
        postCalculInvoiceEnd: build.mutation<PiedFacture, {data: PiedFactureCreatePayload; caseId: string}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.piedFactureCreate,
            args.caseId,
            args.data,
          ),
        }),
        postSaveInvoiceDraft: build.mutation<void, {caseId: string; data: BrouillonFactureCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.brouillonFactureCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW' ],
        }),
        postSaveInvoice: build.mutation<void, {caseId: string; data: EditerFactureCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.editerFactureCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'WORKFLOW', 'INFO' ],
        }),
        postImportInvoice: build.mutation<void, {caseId: string; data: ImportFactureCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.importFactureCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'INVOICE' ],
        }),
        /* Internal Exchanges */
        getMessageList: build.query<EchangeInterne[], EchangeInterneListParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.echangeInterne.echangeInterneList,
            args,
          ),
          providesTags: (result = [], error, arg) => [
            { type: 'MESSAGES', id: 'LIST' },
            ...result.map((r) => ({ type: 'MESSAGES' as const, id: `${r.id}` })),
          ],
        }),
        getMessagesRecipientList: build.query<CodeLabel[], DestinatairesEchangeInterneDetailParams>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.destinatairesEchangeInterneDetail,
            args,
          ),
        }),
        postNewMessage: build.mutation<void, {caseId: string; data: NouveauEchangeInterneCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.dossier.nouveauEchangeInterneCreate,
            args.caseId,
            args.data,
          ),
          invalidatesTags: [ 'MESSAGES' ],
        }),
        postAnswerMessage: build.mutation<void, {id: string; data: RepondreEchangeInterneCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.echangeInterne.repondreEchangeInterneCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: [ 'MESSAGES' ],
        }),
        postForwardMessage: build.mutation<void, {id: string; data: TransfertEchangeInterneCreatePayload}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.echangeInterne.transfertEchangeInterneCreate,
            args.id,
            args.data,
          ),
          invalidatesTags: [ 'MESSAGES' ],
        }),
        patchMarkAsReadMessage: build.mutation<void, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.echangeInterne.marquerCommeLuEchangeInterneUpdate,
            args,
          ),
          invalidatesTags: [ 'MESSAGES' ],
        }),
        patchMarkAsUnreadMessage: build.mutation<void, string>({
          queryFn: async (args)=> processRequest(
            OpenAPI.echangeInterne.marquerCommeNonLuEchangeInterneUpdate,
            args,
          ),
          invalidatesTags: [ 'MESSAGES' ],
        }),
        /* Settings */
        getEurogexList: build.query<Eurogex[], void>({
          queryFn: async (args)=> processRequest(
            OpenAPI.parametrage.eurogexParametrageList,
          ),
        }),
        getEurogex: build.query<Eurogex, {section: string; rubrique: string}>({
          queryFn: async (args)=> processRequest(
            OpenAPI.parametrage.eurogexParametrageDetail,
            args.section,
            args.rubrique,
          ),
        }),
      }
    },
  },
)

/* Export hooks */
export const {
  // Auth
  useAuthenticateMutation,
  // Issue
  useGetIssueQuery,
  useLazyGetIssueQuery,
  useGetIssuesListQuery,
  useLazyGetIssuesListQuery,
  usePostIssueMutation,
  usePatchIssueMutation,
  // IssueType
  useGetIssueTypeQuery,
  useLazyGetIssueTypeQuery,
  useGetIssueTypesListQuery,
  useLazyGetIssueTypesListQuery,
  usePostIssueTypeMutation,
  usePatchIssueTypeMutation,
  // Dashboard Quote Invoice Order
  useGetQuoteListQuery,
  useLazyGetQuoteListQuery,
  useGetQuoteStatusListQuery,
  useLazyGetQuoteStatusListQuery,
  useGetInvoiceListQuery,
  useLazyGetInvoiceListQuery,
  useGetInvoiceStatusListQuery,
  useLazyGetInvoiceStatusListQuery,
  useGetOrderListQuery,
  useLazyGetOrderListQuery,
  useGetOrderStatusListQuery,
  useLazyGetOrderStatusListQuery,
  // Cases
  useGetCaseListQuery,
  useLazyGetCaseListQuery,
  useGetCaseDisasterQuery,
  useLazyGetCaseDisasterQuery,
  usePatchCaseDisasterMutation,
  useGetCaseMissionQuery,
  useLazyGetCaseMissionQuery,
  usePatchCaseMissionMutation,
  useGetDepartmentListQuery,
  useLazyGetDepartmentListQuery,
  useGetCaseInsuranceCompanyListQuery,
  useLazyGetCaseInsuranceCompanyListQuery,
  useGetDisasterListQuery,
  useLazyGetDisasterListQuery,
  // Case
  useGetCaseInfosQuery,
  useLazyGetCaseInfosQuery,
  useGetCaseWorkflowQuery,
  useLazyGetCaseWorkflowQuery,
  usePatchInsuredInformationMutation,
  usePatchWorkflowMissionStateMutation,
  useGetRefuseMissionReasonListQuery,
  useLazyGetRefuseMissionReasonListQuery,
  useGetCaseHistoryQuery,
  useLazyGetCaseHistoryQuery,
  usePostNewCaseEventMutation,
  useGetEventActionListQuery,
  useLazyGetEventActionListQuery,
  // Mails
  useGetCaseMailListQuery,
  useLazyGetCaseMailListQuery,
  usePostMailMutation,
  useGetMailRecipientListQuery,
  useLazyGetMailRecipientListQuery,
  useGetMailSenderListQuery,
  useLazyGetMailSenderListQuery,
  useGetMailTypeListQuery,
  useLazyGetMailTypeListQuery,
  useGetMailTypeQuery,
  useLazyGetMailTypeQuery,
  // Documents
  useGetCaseDocumentListQuery,
  useLazyGetCaseDocumentListQuery,
  usePostDocumentsMutation,
  useGetCaseMediaListQuery,
  useLazyGetCaseMediaListQuery,
  useGetDocumentCategoryListQuery,
  usePostMediaFilesMutation,
  useLazyGetDocumentCategoryListQuery,
  useDeleteDocumentsMutation,
  useDeleteMediaFilesMutation,
  // Intervention
  usePostInterventionMutation,
  useGetInterventionReasonListQuery,
  useLazyGetInterventionReasonListQuery,
  useGetInterventionResultListQuery,
  useLazyGetInterventionResultListQuery,
  useGetInterventionOriginRequestDeferredQuery,
  useLazyGetInterventionOriginRequestDeferredQuery,
  // Contact
  usePostContactRdvMutation,
  // Rendez-vous
  usePostMeasureRdvMutation,
  usePatchMeasureRdvMutation,
  usePostWorksRdvMutation,
  usePatchWorksRdvMutation,
  // Quote & Invoice
  useGetLineTypeListQuery,
  useLazyGetLineTypeListQuery,
  useGetFranchiseQuery,
  useLazyGetFranchiseQuery,
  useGetRSEQuery,
  useLazyGetRSEQuery,
  useGetRemiseQuery,
  useLazyGetRemiseQuery,
  useGetPriceListQuery,
  useLazyGetPriceListQuery,
  useGetMeasureUnitListQuery,
  useLazyGetMeasureUnitListQuery,
  useGetTVARateListQuery,
  useLazyGetTVARateListQuery,
  useGetArticleBordereauListQuery,
  useLazyGetArticleBordereauListQuery,
  useGetDiverseBordereauListQuery,
  useLazyGetDiverseBordereauListQuery,
  // Quote
  useGetQuoteDraftQuery,
  useLazyGetQuoteDraftQuery,
  usePostCalculQuoteEndMutation,
  usePostSaveQuoteDraftMutation,
  usePostSaveQuoteMutation,
  usePostImportQuoteMutation,
  // Invoice
  useGetInvoiceDraftQuery,
  useLazyGetInvoiceDraftQuery,
  usePostCalculInvoiceEndMutation,
  usePostSaveInvoiceDraftMutation,
  usePostSaveInvoiceMutation,
  usePostImportInvoiceMutation,
  // Internal Exchanges
  useGetMessageListQuery,
  useLazyGetMessageListQuery,
  useGetMessagesRecipientListQuery,
  useLazyGetMessagesRecipientListQuery,
  usePostNewMessageMutation,
  usePostAnswerMessageMutation,
  usePostForwardMessageMutation,
  usePatchMarkAsReadMessageMutation,
  usePatchMarkAsUnreadMessageMutation,
  // Settings
  useGetEurogexListQuery,
  useLazyGetEurogexListQuery,
  useGetEurogexQuery,
  useLazyGetEurogexQuery,
} = api
