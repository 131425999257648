/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetCaseDocumentListQuery,
  useGetMailRecipientListQuery,
  useGetMailSenderListQuery,
  useGetMailTypeListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import Loader from 'components/Loader/Loader'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import MailForm from './MailForm'

/* Component declaration ---------------------------------------------------- */
interface CaseMailPageProps {}

const CaseMailPage: React.FC<CaseMailPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: senders = [],
    isFetching: isFetchingSenders,
  } = useGetMailSenderListQuery(caseId)
  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipients,
  } = useGetMailRecipientListQuery(
    {
      dossier: caseId,
      interne: true,
      externe: true,
    },
  )
  const {
    currentData: mailTypes = [],
    isFetching: isFetchingMailTypeList,
  } = useGetMailTypeListQuery(caseId)
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentListQuery({ dossier: caseId })

  const isLoading = useMemo(() => isFetchingSenders || isFetchingMailTypeList || isFetchingRecipients || isFetchingDocuments,
    [
      isFetchingSenders,
      isFetchingMailTypeList,
      isFetchingRecipients,
      isFetchingDocuments,
    ])

  return (
    <div>
      <LargeTitle>
        Nouveau Mail
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Envoyer
        </Button>
      </LargeTitle>
      {isLoading && <Loader />}
      <MailForm
        senders={senders}
        isFetchingSenders={isFetchingSenders}
        recipients={recipients}
        mailTypes={mailTypes}
        documents={documents}
      />
    </div>
  )
}

export default CaseMailPage
