/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Type imports ------------------------------------------------------------- */
import type { Planning } from 'types/Planning'

/* Styled components -------------------------------------------------------- */
const TooltipContainer = styled.div`
  font-size: .85rem;
`

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningTooltip {
  event: Planning;
}

const PlanningTooltip: React.FC<PlanningTooltip> = ({ event }) => {
  return (
    <TooltipContainer>
      <BoldText>
        {DateUtils.APIStrToLocalTimeString(event.dateDebut, { hour: '2-digit', minute: '2-digit' })}
        {' - '}
        {DateUtils.APIStrToLocalTimeString(event.dateFin, { hour: '2-digit', minute: '2-digit' })}
        {' : '}
      </BoldText>
      {event.typeRDV.libelle}
      <div>
        <BoldText>
          {'Dossier : '}
        </BoldText>
        {event.dossierId}
      </div>
      <div>
        <BoldText>
          {'Personnes conviées : '}
        </BoldText>
        {
          event.intervenants?.map((person) => (
            <li key={person.id}>
              {`${person.prenom || ''} ${person.nom}`}
            </li>
          ))
        }
      </div>
      <div>
        <BoldText>
          {'Adresse : '}
        </BoldText>
        <br />
        {getAddress(event.adresse)}
      </div>
      <div>
        <BoldText>
          {'Commentaire : '}
        </BoldText>
        <br />
        {event.commentaire}
      </div>
    </TooltipContainer>
  )
}

export default PlanningTooltip
