/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetEventActionListQuery,
  usePostNewCaseEventMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { PostDossierPayload } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const newEventSchema = Yup.object().shape<Shape<PostDossierPayload>>({
  codeAction: Yup.number().min(0, "L'action est obligatoire").required("L'action est obligatoire"),
  commentaire: Yup.string(),
  label: Yup.string().required('Le libellé est obligatoire'),
})

type NewEventForm = FormikContextType<PostDossierPayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryNewEventModalProps {
  handleClose: () => void;
  caseId: string;
}

const CaseHistoryNewEventModal: React.FC<CaseHistoryNewEventModalProps> = ({
  handleClose,
  caseId,
}) => {
  const [
    submitNewEvent,
  ] = usePostNewCaseEventMutation()
  const {
    currentData: eventActionList = [],
    isFetching: isFetchingEventActionList,
  } = useGetEventActionListQuery()

  const onSubmit = (values: PostDossierPayload, { setSubmitting }: FormikHelpers<PostDossierPayload>) => {
    submitNewEvent({ caseId, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const formikForm: NewEventForm = useForm<PostDossierPayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        codeAction: -1,
        commentaire: '',
        label: '',
      },
      validationSchema: newEventSchema,
    },
  )

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Nouvel évènement
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          <DialogContentContainer>
            <FormBoldTitle required>
              Action Suivi
            </FormBoldTitle>
            <Field
              component={Select}
              name="codeAction"
              displayEmpty
              size="small"
              disabled={isFetchingEventActionList}
            >
              <MenuItem value={-1}>
                Sélectionner
              </MenuItem>
              {
                eventActionList.map((value, index) => (
                  <MenuItem
                    value={value.id}
                    key={`${value.id}-${index}`}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            <FormBoldTitle required>
              Libellé
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Libellé"
              name="label"
              size="small"
            />
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              name="commentaire"
              size="small"
              multiline
              rows={3}
            />
          </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseHistoryNewEventModal
