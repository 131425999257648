/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetDisasterListQuery,
  usePatchCaseDisasterMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Adresse,
  Sinistre,
  SinistreDossierPartialUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const disasterSchema = Yup.object().shape<Shape<SinistreDossierPartialUpdatePayload>>({
  commentaire: Yup.string(),
  adresse: Yup.object().shape<Shape<Adresse>>({
  }).required("L'adresse est obligatoire"),
  date: Yup.string().required('La date est obligatoire'),
  nature: Yup.string().required('La nature est obligatoire'),
  referenceSinistre: Yup.string().required('La ref est obligatoire'),
})

type DisasterForm = FormikContextType<SinistreDossierPartialUpdatePayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
  width: 120px;
`

const DualContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  div {
    width: 100%;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const AddressSeparator = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutDisasterModalProps {
  handleClose: () => void;
  caseId: string;
  disaster: Sinistre;
}

const CaseLayoutDisasterModal: React.FC<CaseLayoutDisasterModalProps> = ({
  handleClose,
  caseId,
  disaster,
}) => {
  const [
    submitDisaster,
  ] = usePatchCaseDisasterMutation()
  const {
    currentData: disasterList = [],
    isFetching: isFetchingDisasterList,
  } = useGetDisasterListQuery()

  const onSubmit = (values: SinistreDossierPartialUpdatePayload, { setSubmitting }: FormikHelpers<SinistreDossierPartialUpdatePayload>) => {
    submitDisaster({ caseId, data: values })
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const formikForm: DisasterForm = useForm<SinistreDossierPartialUpdatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        commentaire: '',
        date: '',
        nature: '',
        adresse: {
          adresse1: '',
          adresse2: '',
          adresse3: '',
          codePostal: '',
          ville: '',
        },
        referenceSinistre: '',
      },
      validationSchema: disasterSchema,
    },
  )

  useEffect(() => {
    formikForm.setValues(disaster)
  }, [ disaster ])

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      adresse: {
        ...formikForm.values.adresse,
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const addressErrorProps = useMemo(() => {
    const addressTouched = (formikForm.touched.adresse as unknown as Adresse)?.adresse1 || false
    const addressError = (formikForm.errors.adresse as unknown as Adresse)?.adresse1 || ''

    return ({
      error: Boolean(addressTouched && addressError),
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }, [ formikForm.errors.adresse, formikForm.touched.adresse ])

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Modifier le sinistre
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          <DialogContentContainer>
            <DualContainer>
              <div>
                <FormBoldTitle required>
                  Nature du sinistre
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="nature"
                  displayEmpty
                  size="small"
                  disabled={isFetchingDisasterList}
                >
                  <MenuItem value="">
                    Sélectionner
                  </MenuItem>
                  {
                    disasterList.map((value, index) => (
                      <MenuItem
                        value={value.libelle || ''}
                        key={`${value.libelle}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </div>
              <div>
                <FormBoldTitle required>
                  Ref sinistre
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ref sinistre"
                  name="referenceSinistre"
                  size="small"
                />
              </div>
            </DualContainer>
            <FormBoldTitle required>
              Date
            </FormBoldTitle>
            <FormikDatePicker name="date" />
            <FormBoldTitle required>
              Adresse
            </FormBoldTitle>
            <AddressAutocomplete
              placeholder="Adresse"
              value={formikForm.values.adresse?.adresse1 || ''}
              onValueChange={(newValue: string) => formikForm.setFieldValue('adresse.adresse1', newValue)}
              onAddressChange={(newValue) => onAddressChange(newValue)}
              size="small"
              {...addressErrorProps}
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse2"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse3"
              size="small"
            />
            <DualContainer>
              <div>
                <FormBoldTitle required>
                  Code postal
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code postal"
                  name="adresse.codePostal"
                  size="small"
                />
              </div>
              <div>
                <FormBoldTitle required>
                  Ville
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ville"
                  name="adresse.ville"
                  size="small"
                />
              </div>
            </DualContainer>
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              name="commentaire"
              size="small"
              multiline
              rows={3}
            />
          </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseLayoutDisasterModal
