/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { getRowsComparator } from 'helpers/tableUtils'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CaseTaskTable from './CaseTaskComponents/CaseTaskTable'
import RoundedChip from 'components/RoundedChip/RoundedChip'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  DataName,
  Order,
} from 'types/Table'
import type { PieceJointe } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const taskSchema = Yup.object({
  filters: Yup.object({
    types: Yup.array(Yup.string()),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type TaskSchema = Yup.InferType<typeof taskSchema>

type TaskForm = FormikContextType<TaskSchema>

/* Styled components -------------------------------------------------------- */
const CustomChip = styled(RoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseTaskPageProps {}

const CaseTaskPage: React.FC<CaseTaskPageProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()
  const [ filteredList, setFilteredList ] = useState<PieceJointe[]>([])

  const formikForm: TaskForm = useForm<TaskSchema>(
    {
      initialValues: {
        filters: {
          types: [],
        },
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: taskSchema,
    },
  )

  useEffect(() => {
    const { types = []} = formikForm.values.filters
    const { order, orderBy } = formikForm.values.sort

    // set list
    setFilteredList([ ].sort(getRowsComparator(order, orderBy)).filter((value) =>
      (types.length === 0 || types.includes(value)),
    ))
  }, [ formikForm.values.filters ])

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <div>
      <LargeTitle>
        Tâches
        <Button variant="contained">
          Nouvelle tâche
        </Button>
      </LargeTitle>
      <Form form={formikForm}>
        <div>
          <FormBoldTitle>
            Type
          </FormBoldTitle>
          <RoundedChip variant="outlined">
            Type
          </RoundedChip>
        </div>
        <CaseTaskTable
          filteredList={filteredList}
          isFetchingList={false}
          setFilteredList={() => null}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Form>
    </div>
  )
}

export default CaseTaskPage
