/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getOrderStatusColor } from 'helpers/caseUtils'
import { formatPrice } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { DashboardCommande } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface DashboardOrderListMobileCardProps {
  order: DashboardCommande;
  handleNavigate: () => void;
}

const DashboardOrderListMobileCard: React.FC<DashboardOrderListMobileCardProps> = ({
  order,
  handleNavigate,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link onClick={handleNavigate}>
            {order.numeroCommande}
          </Link>
          <ColoredSquareChip
            color={getOrderStatusColor(order.etat.code)}
            smaller
          >
            {order.etat.libelle}
          </ColoredSquareChip>
        </TitleContainer>
        {`Assuré : ${order.assure}`}
        <br />
        {`Donneur d'ordre : ${order.donneurDordre || ''}`}
        <br />
        {`Ref mandant : ${order.refMandant}`}
        <br />
        {`Ville : ${order.ville}`}
        <br />
        {`N° Commande : ${order.numeroCommande}`}
        <br />
        {`Montant HT : ${formatPrice(order.montantHT)}`}
        <br />
        {`Nb articles : ${order.nbArticles}`}
        <br />
        {`Articles reçus : ${order.nbArticlesRecus}`}
      </CardContentContainer>
    </CardContainer>
  )
}

export default DashboardOrderListMobileCard
