/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { getAddress } from 'helpers/getAddress'
import { isValidString } from 'helpers/isValidString'
// import { hasCoordinates } from 'helpers/mapHelpers'

/* Component imports -------------------------------------------------------- */
import {
  FmdGoodRounded,
  PhoneRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import type { Planning } from 'types/Planning'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'

/* Styled components -------------------------------------------------------- */
interface EventCardProps {
  eventcolor: string;
  view: CalendarViewTypes;
}

const EventCard = styled.div<EventCardProps>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.eventcolor};
  color: ${(props) => props.theme.palette.secondary.main};
  overflow-y: auto;
  overflow-x: hidden;
  font-size: .85rem;
  display: flex;
  flex-direction: ${(props) => props.view === CalendarViewTypes.Day ? 'column' : 'row-reverse'};
  justify-content: ${(props) => props.view === CalendarViewTypes.Day ? 'space-between' : 'start'};
`

const CardContentContainer = styled.div`
  display: grid;
  grid-template-columns:  7fr 3fr;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  padding: 3px;
  width: 100%;
`

const Name = styled.div`
  font-weight: bold;
`

const CaseContainer = styled.div`
  justify-self: end;
  text-align: end;
  margin-right: 5px;
`

const CaseButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: -5px;
  margin-bottom: 5px;
`

interface StakeholderContainerProps {
  view: CalendarViewTypes;
}

const StakeholderContainer = styled.div<StakeholderContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.view === CalendarViewTypes.Day ? 'row' : 'column'};
  justify-content: ${(props) => props.view === CalendarViewTypes.Day ? 'end' : 'center'};
  gap: 3px;
  margin-bottom: ${(props) => props.view === CalendarViewTypes.Day ? '2' : '0'}px;
  padding: ${(props) => props.view === CalendarViewTypes.Day ? '0px 3px' : '3px 10px 3px 3px'};
`

interface ColoredBarProps {
  color: string;
  view: CalendarViewTypes;
}

const StakeholderBar = styled.div<ColoredBarProps>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: ${(props) => props.view === CalendarViewTypes.Day ? '20px' : '100%'};
  padding: ${(props) => props.view === CalendarViewTypes.Day ? '0px' : '0px 2px'};
  border: 1px solid #00000019;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventDayViewProps {
  event: Planning;
  viewType?: CalendarViewTypes;
}

const PlanningCalendarEventDayView: React.FC<PlanningCalendarEventDayViewProps> = ({ event, viewType = CalendarViewTypes.Day }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const onLocationClick = () => {
    if (location.pathname.indexOf('/carte') === -1) {
      navigate(`/planning/carte/${event.dateDebut}/${event.id}`)
    }
  }

  return (
    <EventCard
      id={`${event.commentaire}#${event.typeRDV?.libelle}`}
      eventcolor={event.typeRDV?.color}
      view={viewType}
    >
      <CardContentContainer>
        <div>
          <Name>
            {event.assure ?? event.typeRDV.libelle}
          </Name>
          <div>
            {getAddress(event.adresse)}
          </div>
          <div>
            <div>
              {event.commentaire}
            </div>
          </div>
        </div>
        {
          isValidString(event.dossierId) &&
            <CaseContainer>
              <Name>
                {event.dossierId}
              </Name>
              {event.refMandant}
              <CaseButtonsContainer>
                {
                  isValidString(getAddress(event.adresse)) &&
                    <CustomIconButton
                      Icon={FmdGoodRounded}
                      onClick={onLocationClick}
                      variant="outlined"
                      color="secondary"
                      label="Voir sur la carte"
                    />
                }
                {
                  isValidString(event.telephones.mobile) &&
                    <a
                      href={`tel:${event.telephones.mobile}`}
                      target="_self"
                    >
                      <CustomIconButton
                        Icon={PhoneRounded}
                        variant="outlined"
                        color="secondary"
                        label="Téléphone"
                      />
                    </a>
                }
              </CaseButtonsContainer>
            </CaseContainer>
        }
      </CardContentContainer>
      <StakeholderContainer view={viewType}>
        {
          event?.intervenants?.map((stakeholder, index) => (
            <StakeholderBar
              key={`${stakeholder.id}-${index}`}
              color={stakeholder.color}
              view={viewType}
            >
              {`${stakeholder.prenom?.[0] || ''}${stakeholder.nom[0]}`}
            </StakeholderBar>
          ))
        }
      </StakeholderContainer>
    </EventCard>
  )
}

export default PlanningCalendarEventDayView
